import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { Incident } from 'src/app/models/incident';
import {
  IncidentSchema,
  IncidentSchemaVersion,
} from 'src/app/models/incident-schema';
import { MenuService } from 'src/app/services/menu/menu.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Entity, loadEntity, NewEntity } from 'src/app/store/common/entity';
import {
  ListEntities,
  loadEntities,
  NewListEntities,
  removeListEntity,
} from 'src/app/store/common/list-entity';
import {
  PaginatedListEntities,
  PaginatedListEntitiesState,
} from 'src/app/store/common/paginated-list-entity';
import {
  OffsetPagination,
  OffsetPaginationState,
} from 'src/app/store/common/pagination';
import { UserState } from 'src/app/store/user/user.state';
import {
  CreateIncidentAction,
  DeleteIncidentAction,
  IncidentCreatedAction,
  LoadIncidents,
  LoadIncidentSchemasAction,
  LoadIncidentSchemaVersionAction,
} from './incidents.actions';
import { IncidentsService } from './incidents.service';
import { IncidentSchemasService } from './incidentSchemas.service';

export interface IncidentsStateModel {
  incidents: PaginatedListEntitiesState<Incident, OffsetPaginationState>;
  schemas: ListEntities<IncidentSchema>;
  currentSchemaVersion: Entity<IncidentSchemaVersion>;
}

export type HasProperty<Key extends keyof any, Value> = Record<Key, Value>;

@State<IncidentsStateModel>({
  name: 'incidents',
  defaults: {
    incidents: OffsetPagination.GetDefaultState(),
    schemas: NewListEntities(),
    currentSchemaVersion: NewEntity(),
  },
})
@Injectable()
export class IncidentsState {
  store = inject(Store);
  incidentsService = inject(IncidentsService);
  incidentSchemasService = inject(IncidentSchemasService);
  menuService = inject(MenuService);
  toastService = inject(ToastService);
  translate = inject(TranslateService);

  incidentPagination = new PaginatedListEntities<
    IncidentsStateModel,
    OffsetPagination
  >('incidents', new OffsetPagination());

  @Selector()
  static incidents(state: IncidentsStateModel) {
    return state.incidents;
  }

  @Selector()
  static schemas(state: IncidentsStateModel) {
    return state.schemas;
  }

  @Selector()
  static currentSchemaVersion(state: IncidentsStateModel) {
    return state.currentSchemaVersion;
  }

  static schema(id: string) {
    return createSelector([this], (state: IncidentsStateModel) => {
      return state.schemas.entities[id];
    });
  }

  @Action(LoadIncidents)
  loadIncidentsToState(
    ctx: StateContext<IncidentsStateModel>,
    { pagination, query }: LoadIncidents
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.incidentPagination.loadEntititesPaginated(
      ctx,
      pagination,
      this.incidentsService.listIncidents(organizationID, pagination, query)
    );
  }

  @Action(CreateIncidentAction)
  createIncident(
    ctx: StateContext<IncidentsStateModel>,
    { spec }: CreateIncidentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.incidentsService.createIncident(organizationID, spec).pipe(
      tap(newIncidentId => {
        this.toastService.showInfo('Incident created', '');

        ctx.dispatch(new IncidentCreatedAction(newIncidentId));
      }),
      catchError(err => {
        this.toastService.showError(
          new Error('Failed to create incident: ' + err.message)
        );
        throw err;
      })
    );
  }

  @Action(LoadIncidentSchemasAction)
  loadIncidentSchemas(ctx: StateContext<IncidentsStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'schemas',
      this.incidentSchemasService.listIncidentSchemas(organizationID)
    );
  }

  @Action(LoadIncidentSchemaVersionAction)
  loadIncidentSchemaVersion(
    ctx: StateContext<IncidentsStateModel>,
    { schemaVersionId }: LoadIncidentSchemaVersionAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntity(
      ctx,
      'currentSchemaVersion',
      this.incidentSchemasService.getIncidentSchemaVersion(
        organizationID,
        schemaVersionId
      )
    );
  }

  @Action(DeleteIncidentAction)
  deleteIncident(
    ctx: StateContext<IncidentsStateModel>,
    { incidentId }: DeleteIncidentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'incidents',
      incidentId,
      (incident: Incident) => {
        return incident.id !== incidentId;
      },
      this.incidentsService.deleteIncident(organizationID, incidentId)
    ).pipe(
      tap(() => {
        this.toastService.showInfo(
          this.translate.instant('toasts.resourceDeleted.title', {
            resource: 'Incident',
          }),
          this.translate.instant('toasts.resourceDeleted.message', {
            resource: 'incident',
          })
        );
      }),
      catchError(err => {
        this.toastService.showError(
          new Error('Failed to delete incident: ' + err.message)
        );
        throw err;
      })
    );
  }
}
