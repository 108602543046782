import { IncidentEvent, IncidentEventPayloadFieldUpdated } from './incident';

export const EventActionMappings = new Map<string, string>([
  ['CREATED', 'incidentEvents.created'],
  ['FIELD_UPDATED', 'incidentEvents.fieldUpdated'],
  ['STATE_UPDATED', 'incidentEvents.stateUpdated'],
  ['PRIORITY_UPDATED', 'incidentEvents.priorityUpdated'],
  ['RESPONDER_ADDED', 'incidentEvents.responderAdded'],
  ['AFFECTED_SERVICE_ADDED', 'incidentEvents.affectedServiceAdded'],
  ['AFFECTED_SERVICE_REMOVED', 'incidentEvents.affectedServiceRemoved'],
  [
    'AFFECTED_SERVICE_IMPACT_CHANGED',
    'incidentEvents.affectedServiceImpactChanged',
  ],
  [
    'COMMUNICATION_CHANNEL_CREATED',
    'incidentEvents.communicationChannelCreated',
  ],
  ['COMMENT_ADDED', 'incidentEvents.commentAdded'],
  ['ROLE_ASSIGNED', 'incidentEvents.roleAssigned'],
  ['ROLE_UNASSIGNED', 'incidentEvents.roleUnassigned'],
  ['STATUSPAGE_LINKED', 'incidentEvents.statuspageLinked'],
  ['STATUSPAGE_UNLINKED', 'incidentEvents.statuspageUnlinked'],
  ['TIMINGS_UPDATED', 'incidentEvents.timingsUpdated'],
  ['RELATED_ALERT_ADDED', 'incidentEvents.relatedAlertAdded'],
]);

export const EventFieldMappings = new Map<string, string>([
  ['state', 'incidentEvents.fieldMappings.state'],
  ['summary', 'incidentEvents.fieldMappings.summary'],
  ['priority', 'incidentEvents.fieldMappings.priority'],
  ['title', 'incidentEvents.fieldMappings.title'],
]);

export const EventTypeToHeader = (event: IncidentEvent): string => {
  if (event.type === 'FIELD_UPDATED') {
    const payload = event.payload as IncidentEventPayloadFieldUpdated;
    return EventFieldMappings.get(payload.field) || '';
  }

  return EventActionMappings.get(event.type) || '';
};
